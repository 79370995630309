import React from 'react'

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";

import { Buffer } from "buffer";

// import MDInput from "components/MDInput";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { RecoveryPasswordPage } from './RecoveryPasswordPage';
import md5 from "md5";
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Checkbox } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators"; 
import { Dialog } from "@progress/kendo-react-dialogs";

// @mui material components


//Componentes
import LoginPage from './LoginPage';

// Hooks

import { useForm, useAuthStore, useUserStore } from "hooks";

export const SecondFactorPage = ({ user_email, password }) => {

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
        topRight: {
            top: 5,
            left: "95%",
            alignItems: "flex-end",
        },
        bottomLeft: {
            bottom: 0,
            left: "92%",
            alignItems: "flex-start",
        },
    }
    
    const { startLogin, errorMessage, user, status, apiLoginInfo } = useAuthStore();

    const { verificationCode, 
        errorCodeVerificationMessage, 
        apiCodeVerification,
        
        passwordCode, apiCodePassword, clearCodePage, errorUserMessage, errorCodeMessage, statusUserLoad, statusUserCode } = useUserStore();

    const [backRecoveryAction, setBackRecoveryAction] = React.useState(true)
    const [recoveryPasswordPage, setRecoveryPasswordPage] = React.useState(false)
    const [useremail, setUseremail] = React.useState(user_email)
    const [codeVerification, setCodeVerification] = React.useState()
    const [newPassword, setNewPassword] = React.useState()
    const [reconfirmPassword, setReconfirmPassword] = React.useState()
    const [content, setContent] = React.useState();
    const [notification, setNotification] = React.useState(false)
    const [notificationSucess, setNotificationSucess] = React.useState(false)
    const [loginForm, setLoginForm] = React.useState(false)
    const [recoveryAction, setRecoveryAction] = React.useState(false)
    const [loading, setLoading] = React.useState(false);

    const handleVerificationCode = (event) => {
        //console.log(event.target.value)
        setCodeVerification(event.target.value)
    }

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value)
    }

    const handleReconfirmPasswordChange = (event) => {
        setReconfirmPassword(event.target.value)
    }

    const handleVerifyCode = (event) => {
        event.preventDefault();
        setLoading(true); // Mostrar el indicador de carga

        const request_verification = {
            user_name: user_email,
            password: password,
            origen_ip: "127.0.0.1",
            access_type: "WEB",
            access_code: codeVerification

        }
        console.log(request_verification)
        verificationCode(request_verification)
    }

    //const handleBackRecovery = (event) => {
        //console.log(event)
        //setBackRecoveryAction(false)
       // setRecoveryPasswordPage(true)
    //}

    React.useEffect(() => {
        console.log(errorCodeVerificationMessage)

        if (errorCodeVerificationMessage !== undefined) {
            console.log(errorCodeMessage)
            setLoading(false); // Detener el indicador de carga
            setContent(errorCodeVerificationMessage)
            setNotification(true)
            setTimeout(() => {
                setNotification(false);
            }, 4000);
        }

        if (errorCodeVerificationMessage === "No hay resultados") {
            setContent("El código ingresado es diferente al código enviado al correo registrado")
            setNotification(true)
            setTimeout(() => {
                setNotification(false);
            }, 4000);
    
        }
    }, [errorCodeVerificationMessage])

   

    React.useEffect(() => {
        if (statusUserCode === '1') {
            setLoading(false); // Detener el indicador de carga
            //setContent("Contraseña Actualizada Exitosamente")
            //setNotificationSucess(true)
            setTimeout(() => {
                setNotificationSucess(false);
                //setBackRecoveryAction(false)
                //setRecoveryPasswordPage(false)
            }, 2000);

            setTimeout(() => {
                //window.location.href = 'https://localhost:300/safeentry/login'
                //window.location.href = 'https://iyd.telconet.ec/safeentry/login'
                document.location.href = "/safeEntry/estadoLocalidad";
            }, 1000)
            //setLoginForm(true)
            //setRecoveryAction(true)
        }
        
        if (user.token !== undefined) {
            const enterprises = Buffer.from(
              localStorage.getItem("key1"),
              "base64"
            ).toString("ascii");
      
            const dataEnterprises = JSON.parse(enterprises);
            //console.log(dataEnterprises)
            //console.log(dataEnterprises.length)
      
            localStorage.setItem(
              "enterprise-select",
              dataEnterprises[0].enterprise_id,
            );
            const companies = Buffer.from(
              localStorage.getItem("key2"),
              "base64"
            ).toString("ascii");
              //console.log(companies)
            const dataCompanies = JSON.parse(companies);
            //console.log(dataCompanies)
            localStorage.setItem("company-select", dataCompanies[0].company_id);
      
      
            const lables = Buffer.from(
              localStorage.getItem("key3"),
              "base64"
            ).toString("ascii");
            //console.log(lables)
            //const dataLables = JSON.parse(lables);
            localStorage.setItem("label-select", lables);
        
          };
    }, [statusUserCode])


    return (
        <div className="row">
            <div className="offset-sm-12">

            {loading && (
            <Dialog>
                <Loader size="large" type="converging-spinner" />
            </Dialog>
        )}

                {backRecoveryAction && (
                    <form className="k-form" onSubmit={handleVerifyCode}>
                        <div >
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="h3"
                                color="light"
                                fontWeight="medium"
                                textGradient
                                style={{ textAlign: "center", display: "block", margin: "0 auto" }}
                            >
                                Segundo Factor de Autenticación
                            </MDTypography>

                            <br />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="h6"
                                color="light"
                                fontWeight="medium"
                                textGradient
                                style={{ textAlign: "center", display: "block", margin: "0 auto" }}
                            >
                                Ingrese el código de seguridad enviado a su correo electrónico
                            </MDTypography>
                            <br/>

                            <Input
                                validityStyles={false}
                                name="codeVerification"
                                placeholder="Código de Autenticación"
                                //type="email"
                                style={{
                                    width: "100%",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(102, 105, 98, 0.6)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(255, 255, 255, 0.3)",
                                    textAlign: "center", 
                                    display: "block", 
                                    margin: "0 auto"
                                }}
                                required
                                maxLength={6}
                                minLength={6}

                                //value={codeVerification}
                                onChange={handleVerificationCode}
                                validationMessage="Obligatorio"
                            />
                            <br />
                        </div>
                        <div className="k-form-buttons">
                            <Button
                                style={{
                                    width: "100%",
                                    height: "42px",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(0, 0, 0)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(102, 105, 98, 0.6)",
                                }}
                                //className="buttons-container-button"
                                // themeColor={"dark"}
                                size="small"
                                type="submit"
                            // disabled={disabledButton}
                            >
                                VERIFICAR
                            </Button>
                            {/* <Button
                                style={{
                                    width: "100%",
                                    height: "42px",
                                    color: "rgb(0, 0, 0)",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(102, 105, 98, 0.6)",
                                }}
                                //className="buttons-container-button"
                                // themeColor={"dark"}
                                size="small"
                                //type="submit"
                                onClick={handleBackRecovery}
                            //disabled={disabledButton}
                            >
                                REGRESAR
                            </Button> */}

                            {notification && (
                                <NotificationGroup
                                    style={
                                        position.bottomRight
                                    }>
                                    <Notification
                                        key="error"
                                        type={{
                                            style: "error",
                                            //margin: "center"
                                        }}

                                        closable={true}
                                    //onClose={() => viewNotification(false)}
                                    >
                                        {content}
                                    </Notification>

                                </NotificationGroup>
                            )
                            }
                        </div>
                    </form>

                )}
                {recoveryPasswordPage && (
                    <RecoveryPasswordPage />
                )}
                {loginForm && (
                    <LoginPage />
                )}

                {notificationSucess && (
                    <NotificationGroup
                        style={
                            position.bottomRight
                        }>
                        <Notification
                            key="success"
                            type={{
                                style: "success",
                                //margin: "center"
                            }}

                            closable={true}
                        //onClose={() => viewNotification(false)}
                        >
                            {content}
                        </Notification>

                    </NotificationGroup>
                )
                }

            </div>
        </div>
    )
}
